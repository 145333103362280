import React from "react"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { convertRaw, renderRawText } from "../../helpers/rich-text"

const BueroInfo = ({ address, phone, email, applicationEmail }) => {
  return (
    <div className="flex flex-col">
      {address && (
        <div className="mb-4">
          <div className="tracking-wider text-gray-600 uppercase text-base mb-1">
            Adresse
          </div>
          <div className="text-sm text-gray-500 tracking-widest">
            {documentToReactComponents(convertRaw(address), renderRawText)}
          </div>
        </div>
      )}
      {phone && (
        <div className="mb-4 pb-5">
          <div className="tracking-wider text-gray-600 uppercase text-base mb-1">
            Telefon
          </div>
          <a
            href={`tel:${phone}`}
            className="text-sm text-gray-500 tracking-widest underline underline-offset-2 hover:text-gold"
          >
            {phone}
          </a>
        </div>
      )}
      {email && (
        <div className="mb-4 pb-5">
          <div className="tracking-wider text-gray-600 uppercase text-base mb-1">
            E-Mail
          </div>
          <a
            href={`mailto:${email}`}
            className="text-sm text-gray-500 tracking-widest underline underline-offset-2 hover:text-gold"
          >
            {email}
          </a>
        </div>
      )}
      {applicationEmail && (
        <div className="mb-4">
          <div className="tracking-wider text-gray-600 uppercase text-base mb-1">
            Bewerbungen / Applications
          </div>
          <a
            href={`mailto:${applicationEmail}`}
            className="text-sm text-gray-500 tracking-widest underline underline-offset-2 hover:text-gold"
          >
            {applicationEmail}
          </a>
        </div>
      )}
    </div>
  )
}

BueroInfo.propTypes = {
  address: PropTypes.object,
  phone: PropTypes.string,
  email: PropTypes.string,
  applicationEmail: PropTypes.string,
}

export default BueroInfo
