// Gatsby supports TypeScript natively!
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/container"
import Grid from "../components/grid"
import BueroInfo from "../components/buero/info"

const Kontakt = ({ path, data: {buero: { keyvisual, map, address, phone, email, applicationEmail }}}) => {

    return (
      <Layout path={path}>
        <Seo title="Büro" />
        <Container fullWidth maxWidth5xl>
          {keyvisual && (
            <GatsbyImage
              className="aspect-w-16 aspect-h-7 w-full h-full object-cover"
              image={keyvisual?.gatsbyImageData}
              alt={keyvisual?.title ? keyvisual?.title : ""}
            />
          )}
        </Container>
        <Container verticalSpaceSm>
          <Grid>
            <div className="col-span-1 md:col-span-2 lg:col-span-2">
              {map && (
                <GatsbyImage
                  image={map?.gatsbyImageData}
                  alt={map?.title ? map?.title : ""}
                />
              )}
            </div>
            <div className="col-span-1">
              <BueroInfo
                address={address}
                phone={phone}
                email={email}
                applicationEmail={applicationEmail}
              />
            </div>
          </Grid>
        </Container>
      </Layout>
    )
}

export default Kontakt

export const pageQuery = graphql`
  query BueroQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    buero: contentfulBuro(id: {eq: "5a7614eb-4e72-56b4-a852-656618bc3359"}) {
      address {
        raw
      }
      applicationEmail
      email
      phone
      keyvisual {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          quality: 40
        )
      }
      map {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          quality: 40
        )
      }
    }
  }
`
